<template>
    <div>
        <a id="share" @click="openPopover" :class="{ active: active }">
            <simple-svg
                :filepath="this.getSvgFilePath('share')"
                width="20px"
                height="20px"
            ></simple-svg>
        </a>
        <b-popover target="share" placement="bottomleft">
            <template slot="title">
                <div class="d-flex align-items-end m-2">
                    <simple-svg
                        :filepath="this.getSvgFilePath('share')"
                        width="20px"
                        height="20px"
                        fill="rgba(51, 153, 255, .3)"
                    ></simple-svg>
                    <span class="mx-2">Share</span>
                </div>
            </template>
            <div
                class="d-flex justify-content-around"
                style="min-width: 160px;"
            >
                <a
                    :href="facebookLink"
                    target="_blank"
                    @click="notifyShare('facebook')"
                >
                    <simple-svg
                        :filepath="this.getSvgFilePath('facebook')"
                        width="20px"
                        height="20px"
                    ></simple-svg>
                </a>
                <a
                    :href="emailLink"
                    target="_blank"
                    @click="notifyShare('email')"
                >
                    <simple-svg
                        :filepath="this.getSvgFilePath('envelope')"
                        width="20px"
                        height="20px"
                    ></simple-svg>
                </a>
                <a
                    :href="twitterLink"
                    target="_blank"
                    @click="notifyShare('twitter')"
                >
                    <simple-svg
                        :filepath="this.getSvgFilePath('twitter')"
                        width="20px"
                        height="20px"
                    ></simple-svg>
                </a>
            </div>
        </b-popover>
    </div>
</template>
<script>
import BPopover from 'bootstrap-vue/es/components/popover/popover'

export default {
    components: { BPopover },
    props: ['sermon'],
    data() {
        return {
            active: false
        }
    },
    methods: {
        openPopover: function() {
            this.active = !this.active
            this.$root.$emit('bv::hide::popover')
            if (this.active) {
                this.$root.$emit('bv::show::popover', 'share')
            }
        },
        notifyShare(type) {
            let shareData = new FormData()
            shareData.append('type', type)
            shareData.append('sermon_id', this.sermon.id)

            navigator.sendBeacon(
                `//api.${window.base_domain}/logger/interaction`,
                shareData
            )
        },
        getShareLink(type) {
            let isEmbed = location.hostname.match(/^embeds./);
            let domain  = window.base_domain;
            let withinAdminScreen = this.getQueryParam("eventSettings") && this.getQueryParam("eventSettings")[0]==="true";
            let cSlug  = window.Church.slug;
            let cName  = window.Church.name;
            let sSlug  = this.sermon.slug;
            let sTitle = this.sermon.title;
            let search = window.location.search.replace("eventSettings=true","");
            let url    = window.location.href;
            // live
            if (!sSlug) {
                url = `https://${cSlug}.${domain}/live`
            }
            if (isEmbed) {
                if (withinAdminScreen) {
                    let path = window.location.pathname + search;
                    path = path.replace(`/${cSlug}`,"");
                    url = `https://${cSlug}.${domain}` +  path;
                } else {
                    // parent document
                    url = (window.location != window.parent.location)
                        ? document.referrer
                        : document.location.href;
                }
            } else {
                if (sSlug) {
                    url    = `https://${cSlug}.${domain}/sermons/${sSlug}`;
                }
            }
            
            url = encodeURIComponent(url);
            switch(type) {
                case 'facebook':
                    return `https://www.facebook.com/sharer/sharer.php?u=${url}`;
                    break;
                case 'twitter':
                    return `https://twitter.com/intent/tweet?url=${url}&text=${cName} - ${sTitle}`;
                    break;
                case 'email':
                    return `mailto:?subject=${cName} - ${sTitle}&body=${url}`
                    break;
                default:
                    return null;
            } 
        }
    },
    created() {
        //Post up all the sermon/url infos that are needed for V2 interface and the bottom share icons:
        parent.postMessage(['sermonCurrent', {
            'facebook' : this.getShareLink("facebook"),
            'email' : this.getShareLink("email"),
            'twitter' : this.getShareLink("twitter"),
            'sermonID' : this.sermon.id,
            'baseDomain' : window.base_domain
        }], "*");
    },
    computed: {
        facebookLink() {
            return this.getShareLink("facebook");
        },
        emailLink() {
            return this.getShareLink("email");
        },
        twitterLink() {
             return this.getShareLink("twitter");
        }
    },
    watch: {
        sermon: function() {
            this.active = false
        }
    },
    mounted() {
        this.$root.$on('bv::popover::hide', bvEventObj => {
            if (bvEventObj.target.id == 'share') {
                this.active = false
            }
        })
    }
}
</script>
